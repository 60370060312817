:root {
  --red: #C30101/*#AD3B3B*/;
  --blue: #415060;
  --mid-gray: #93989D;
  --dark-gray: #343234;
  --light-gray: #C8C8D0;
  --lighter-gray: #EEEEF1;
  --white: #FFFFFF;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    /*src: url(https://example.com/MaterialIcons-Regular.eot);*/ /* For IE6-8 */
    src: url('data-url:../fonts/material-design-icons/MaterialIconsRound-Regular.otf')
}
.material-icons {
    display: inline-block;
    font-family: 'Material Icons';
    font-size: 24px;  /* Preferred icon size */
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
  
    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;
  
    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;
  
    /* Support for IE. */
    font-feature-settings: 'liga';
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif;
}

details > summary {
  list-style: none;
}

details summary::-webkit-details-marker {
  display:none;
}

#app {
  position: relative;
}

.app-header {
  color: var(--white);
  display: flex;
  height: 64px;
  display: grid;
  grid-template-columns: clamp(250px, 50vw, 35vw) 1fr;
}

.app-title,
.stage-title {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 40px;
  margin: 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: lighter;
}

.app-title {
  position: relative;
  background-color: var(--dark-gray);
  justify-content: flex-end;
}
.app-title::after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-left: 16px solid var(--dark-gray);
  border-bottom: 12px solid transparent;
  right: -16px;
  position: absolute;
}

.stage-title {
  background-color: var(--mid-gray); 
  padding-left: 40px;
}

.fullscreen-wrapper {
  position: absolute;
  top: 64px;
  z-index: -1;
  width: 100%;
  height: calc(100vh - 64px);
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000000;
}

.video-preview {
  height: 100%;
  width: auto;
}
.video-preview.player {
  width: 100%;
}

.instructions-details {
  box-sizing: border-box;  
  padding: 20px 40px !important;
  border-radius: 40px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.35);
  color: var(--white);
  z-index: 1;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.instructions-details[open] {
  width: 275px;
  border-radius: 20px !important;
}

.instructions-details:not(.instructions-details[open]) {
  width: 110px;
}

.instructions-details > summary {
  text-align: right;
}

.instructions-details > summary > span {
  font-size: 30px;
}

.instructions-details[open] > summary > .show { display: none; }
.instructions-details[open] > summary > .hide { display: block; }
.instructions-details:not(.instructions-details[open]) > summary > .hide { display: none; }
.instructions-details:not(.instructions-details[open]) > summary > .show { display: block; }

.instructions {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 20px;
}

.current-delay-time {
  position: absolute;
  z-index: 2;
  width: 100px;
  font-size: 30px;
  line-height: 100px;
  color: var(--white);
  background: rgba(255, 255, 255, 0.3);
  text-align: center;
  border-radius: 50%;
}

.progress-bar {
  position: absolute;
  top: 0;
  z-index: 2;
  width: 100%;
}

.progress-bar .gauge {
  height: 7px;
  background: var(--red);
  transition: width 0.15s ease-in-out;
  opacity: 0;
}

.recording-controls {
  position: absolute;
  bottom: 95px;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 40px;
  width: 100%;
  margin: auto;
}

.control-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border: none!important;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.15s ease-in-out;
}
.control-btn:hover,
.control-btn:focus {
  background-color: rgba(0, 0, 0, 0.75);
}

.control-btn-icon {
  display: block;
  font-size: 30px;
  line-height: 30px;
  color: var(--white);
}

.start-recording-btn,
.stop-recording-btn {
  width: 80px;
  height: 80px;
}
.start-recording-btn > .control-btn-icon,
.stop-recording-btn > .control-btn-icon {
  font-size: 35px;
  line-height: 35px;
}

.stop-recording-btn {
  background-color: red;
}
.stop-recording-btn > .control-btn-icon {
  color: var(--white);
}

.control-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@media (max-width: 767.98px) {
  .app-title,
  .stage-title {
    font-size: 18px;
    line-height: 18px;
  }

  .instructions {
    font-size: 14px;
    line-height: 18px;
  }

  .recording-controls {
    bottom: 520px;
    column-gap: 25px;
  }
}